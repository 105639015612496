/* Intro Section - Light Grey Background & Center Alignment */
.intro-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    padding: 40px 20px;
    background-color: #f8f9fa; /* Light Grey Background */
    transition: transform 0.3s ease-in-out;
}

/* "Dancing" Effect - Moves Entire Section on Hover */
.intro-section:hover {
    transform: translateY(-5px); /* Moves section up slightly */
}

/* Intro Image */
.intro-image img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0 0 7px #FFBB00;
    margin-right: 20px;
}

/* Intro Content */
.intro-content {
    text-align: center;
    max-width: 600px;
}

.intro-content h1 {
    font-size: 2rem;
    color: rgb(0, 84, 147);
    margin-bottom: 10px;
}

/* Intro Quote */
.intro-quote {
    font-size: 1.2rem;
    font-style: italic;
    color: rgb(0, 84, 147);
    margin-bottom: 20px;
}

/* Responsive - Stack on Small Screens */
@media (max-width: 768px) {
    .intro-section {
        flex-direction: column;
        text-align: center;
    }

    .intro-image img {
        margin-bottom: 15px;
    }
}
