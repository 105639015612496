.contact-me-container {
    display: flex;
    flex-direction: column; /* Align text and image in a row */
    width: 70%;
    background-color: #ffffff; /* Optional: Add background color */
    margin: auto;
    margin-top: 30px;
  }
  
  .contact-details {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  .book-session-link {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 15px;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #4285f4;
    border: 1px solid #ccc;
    margin-left: 10px;
  }

  .contact-me-card {
    display: inline;
    flex-direction: column;
    padding: 30px;
    margin-right: 20px;
    padding: 15px;
    margin:0 auto;
  }

  .contact-me-card h1 {
    font-size: 2.5rem; /* Adjust as needed */
    margin-bottom: 20px;
    color: rgb(0, 84, 147);
  }

  .contact-me-card h2 {
    font-size: 2rem; /* Adjust as needed */
    margin-bottom: 20px;
    color: rgb(0, 84, 147);
  }

  .contact-me-card p {
    font-size: 1.5rem; /* Adjust as needed */
    margin-bottom: 20px;
    font-style: italic;
    color: black;
  }

  .topmate-logo {
    width: 40px;
  }
  