/* Container styling */
.resources-page-container {
  display: flex;
  flex-direction: column; /* Align text and image in a row */
  width: 70%;
  background-color: #ffffff; /* Optional: Add background color */
  margin: auto;
  margin-top: 30px;
}

.resources-content {
  flex: 2;
  padding-right: 20px;
}

.resources-content h1 {
  text-align: center;
  font-size: 2rem; /* Adjust as needed */
  margin-bottom: 20px;
  color: rgb(0, 84, 147);
}

.resources-content a {
    color: #0088cc;
    outline: none;
    text-decoration: none;
}

.resources-content a:hover {
    text-decoration: underline;
}

.resources-content p {
  font-size: 1.2rem; /* Adjust as needed */
  line-height: 1.6; /* Adjust for better readability */
  margin-bottom: 20px;
  text-align: left;
}

.resources-content h3 {
    text-align: left;
    font-size: 1.2rem; /* Adjust as needed */
    margin-bottom: 20px;
    color: rgb(0, 84, 147);
}

.resources-content li {
    font-size: 1rem; /* Adjust as needed */
    line-height: 1.6; /* Adjust for better readability */
    margin-bottom: 20px;
    text-align: left;
  }
  
.resources-content ul {
list-style-type: disc;
}

.resources-content p1 {
font-size: 0.8rem; /* Adjust as needed */
line-height: 1.6; /* Adjust for better readability */
margin-bottom: 20px;
text-align: left;
}

