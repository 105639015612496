/* Container styling */
.send-lesson-email-container {
    display: flex;
    flex-direction: column; /* Align text and image in a row */
    width: 70%;
    background-color: #ffffff; /* Optional: Add background color */
    margin: auto;
    margin-top: 30px;
}

.send-lesson-email-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5em;
  }

  .send-lesson-button {
    width: 200px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #04295b;
    color: #ffffff;
    font-size: 16px;
  }

  .send-lesson-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Full width */
}