
.header-container {
    width: 80%; /* Adjust the width here */
    margin: 0 auto; /* Center the container */
    max-width: 1200px; /* Optionally set a max width */
    padding: 0 20px; /* Add padding if needed */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color:  '#16361b';
}

/* src/components/Header.css */

header {
    background-color: #fff; /* Adjust as needed */
    padding: 10px;
}

.logo {
    display: flex;
    align-items: center;
}

.logo-image {
  width: 150px;
  max-width: 100%;
}

.HeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.Nav li {
    margin: 0 15px;
}

.Nav a {
    text-decoration: none;
    color: #333; /* Adjust color as needed */
}


  
nav {
  display: flex;
  gap: 15px;
}

.sign-in-button {
  margin-right: 0;
}

.user-profile-dropdown {
  position: relative;
  display: block;
}

.user-initials {
  background-color: #673de6;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 0 2px #04295b; /* Optional: Add shadow */
  padding: 12px;
  min-width: 160px;
  z-index: 1;
  right: 0;
}

.user-profile-dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a, .dropdown-content button {
  text-decoration: none;
  display: block;
  color: #333;
  padding: 8px;
  border: none;
  background: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

/* Hide menu by default in mobile view */
.nav-menu {
  display: none;
}

/* When open, display the menu */
.nav-menu.open {
  display: flex;
}


/* Mobile view: hide the menu and show the toggle button */
@media (max-width: 768px) {
  .nav-container {
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .nav-menu {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    background-color: #ffffff;
    z-index: 1000;
    padding: 10px;
    box-shadow: 0 0 2px #04295b; /* Optional: Add shadow */
    margin: auto;
  }

  .nav-container .sign-in-button {
    margin-left: 20px;
  }

  /* Show the menu when the open class is added */
  .nav-container.open {
    display: contents;
  }

  /* Show the menu when the open class is added */
  .nav-menu.open {
    display: flex;
  }

  /* Display the menu button on mobile */
  .menu-toggle {
    display: flex;
    align-items: right;
    cursor: pointer;
  }
}

/* Hide menu toggle button in desktop view */
@media (min-width: 769px) {
  .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .nav-container .sign-in-button {
    margin-left: 20px;
  }

  .nav-menu {
    display: flex;
  }

  .menu-toggle {
    display: none;
  }
}

.menu-icon {
  height: 24px; /* Adjust this size according to your icon */
  width: 24px;  /* Adjust this size according to your icon */
  margin-right: 8px; /* Space between icon and text */
}

.menu-text {
  font-size: 1.3rem;
  color: "${theme.colors.text}";
  margin-left: 20px;
  font-family: "${theme.fonts.secondary}";
  text-transform: uppercase;
  font-weight: "${theme.typography.body.fontWeight}";
  line-height: "${theme.typography.body.lineHeight}";
}

.user-profile-text {
  font-size: 1rem;
  color: "${theme.colors.text}";
  margin-left: 20px;
  font-family: "${theme.fonts.secondary}";
  text-transform: uppercase;
  font-weight: "${theme.typography.body.fontWeight}";
  line-height: "${theme.typography.body.lineHeight}";
}