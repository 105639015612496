.challenges-container {
    display: flex;
    flex-direction: column; /* Align text and image in a row */
    width: 70%;
    background-color: #ffffff; /* Optional: Add background color */
    margin: auto;
    margin-top: 30px;
}

.challenges-content {
    flex: 2;
    padding-right: 20px;
}

.challenges-content h1 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    color: rgb(0, 84, 147);
}

.challenges-content p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: left;
}

.challenges-content a {
    color: #0088cc;
    outline: none;
    text-decoration: none;
}

.challenges-content a:hover {
    text-decoration: underline;
}

/* List Styling */
ol, ul {
    margin-left: 30px; /* Indent lists */
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

ol {
    list-style-type: decimal; /* Standard ordered list numbers */
}

ul {
    list-style-type: disc; /* Standard unordered list bullets */
}

li {
    margin-bottom: 10px; /* Add spacing between list items */
}

li::marker {
    font-size: 1.5rem;
    color: rgb(0, 84, 147); /* Custom bullet color */
}

.challenges-list {
    font-size: 1.2rem;
}

/* Big and Small Bullet Lists */
.big-bullet {
    list-style-type: disc;
    list-style-position: inside;
    margin-left: 20px;
}

.big-bullet li {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 10px;
}

.big-bullet li::marker {
    font-size: 1.5rem;
    color: rgb(0, 84, 147);
}

.small-bullet {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 20px;
}

.small-bullet li {
    font-size: 1rem;
    line-height: 1.4;
    margin-bottom: 10px;
}

.small-bullet li::marker {
    font-size: 1.2rem;
    color: rgb(0, 84, 147);
}

/* Highlighted Text */
.highlighted-text {
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 5px;
    font-family: monospace;
    font-size: 1rem;
    display: inline-block;
    color: #333;
}

.challenges-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.challenges-responsive-image {
    max-width: 100%;
    height: auto;
}
