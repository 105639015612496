/* Container for the entire sign-in page */
.sign-in-container {
    display: flex;
    flex-direction: column; /* Align text and image in a row */
    width: 30%;
    background-color: #ffffff; /* Optional: Add background color */
    margin: auto;
    margin-top: 30px;  
  }

  .sign-in-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5em;
  }

  .sign-in-container p1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 0.8em;
  }

  .sign-in-container a {
    text-align: center;
    margin-top: 20px;
    font-size: medium;
    color: #000000;
    transition: all 0.3s ease;
    text-decoration: underline;
    cursor: pointer;
  }

  .sign-in-container a:hover {
    color: #FFBB00;
  }

  
  .input-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    width: 50%; /* Reduce width */
    max-width: 500px; /* Limit maximum width */
    min-width: 250px; /* Ensure it's usable on smaller screens */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #04295b;
    border-radius: 5px;
    margin: 0 auto 20px; /* Center align and add space below */
    display: block; /* Ensure it takes up its own line */
  }
  
  input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .sign-in-info h1 {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .sign-in-info p {
    font-size: 1em;
    margin-bottom: 20px;
  }
  
  .sign-in-info ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  /* Sign-in form styling */
  .sign-in-form {
    flex: 1;
    padding: 30px;
    background-color: white;
    border-radius: 8px;
    width: 50%;
    border: 1px solid #dddddd;
    padding: 15px;
    color: black;
    border-radius: 5px;
    box-shadow: 0 0 1px #FFBB00; /* Optional: Add shadow */
  }
  
  h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
  }

  p {
    text-align: center;
  }

  .forgot-password-link {
    text-align: right;
    position: relative;
    font-size: medium;
    color: #0069d2;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  input {
    padding: 12px;
    margin-bottom: 15px;
  }
  
  /* Consistent spacing for buttons */
  .update-account-button,
  .create-account-button,
  .email-sign-in-button,
  .code-insights-signin-button,
  .email-subscribe-button,
  .google-sign-in-button {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 15px;
  }
  
  .email-sign-in-button {
    background-color: #000000;
    color: #FFBB00;
    font-size: 16px;
  }
  
  .google-sign-in-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px; /* Fixed width */
    height: 40px; /* Adjust height as needed */
    margin: 0 auto; /* Centers the button horizontally */
    background-color: white;
    color: #4285f4;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

  /* Ensure the parent container properly centers its content */
  .sign-in-container {
      display: flex;
      flex-direction: column;
      align-items: center; /* Centers all children, including the button */
      justify-content: center;
      width: 100%;
      text-align: center;
  }


  .google-logo {
      width: 20px;
      margin-right: 10px;
  }

  
  .form-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: right;
  }

  .form-footer a{
    text-align: center;
    font-size: medium;
    color: #000000;
    transition: all 0.3s ease;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 5px;
    margin-top: auto;
  }

  a {
    color: #4285f4;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }

  .create-account-link {
    text-align: center;
    margin-top: 20px;
  }
  
  .create-account-button-signin {
    background-color: #000000;
    color: #FFBB00;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .create-account-button-signin:hover {
    background-color: #000000;
  }

  .password-container {
    position: relative;
  }
  
  .toggle-password {
    position: absolute;
    right: 5%;
    top: 35%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 0.8em;
    color: #888;
  }
  
  