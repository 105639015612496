/* Container styling */
.my-account-page-container {
  display: flex;
  flex-direction: column; /* Align text and image in a row */
  width: 70%;
  background-color: #ffffff; /* Optional: Add background color */
  margin: auto;
  margin-top: 30px;
}

.my-account-page-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5em;
  }

  .my-account-page-container a {
    text-align: center;
    margin-top: 20px;
    font-size: medium;
    color: #000000;
    transition: all 0.3s ease;
    text-decoration: underline;
    cursor: pointer;
  }

  .my-account-page-container a:hover {
    color: #FFBB00;
  }

  /* Center-align Save Changes button */
.update-account-button {
  display: block;
  margin: 20px auto; /* Centers the button */
  width: 200px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #04295b;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
}

  .update-account-button:hover {
    background-color: #04295b;
  }

  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }

  /* Center-align labels and inputs */
.form-group {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align labels and inputs */
  text-align: center; /* Center text inside labels */
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 14px;
}



  
