.lessons-container {
    display: flex;
    flex-direction: column; /* Align text and image in a row */
    width: 70%;
    background-color: #ffffff; /* Optional: Add background color */
    margin: auto;
    margin-top: 30px;
    
}

.lessons-content {
    flex: 2;
    padding-right: 20px; /* Space between text and image */
}

.lessons-content h1 {
    text-align: center;
    font-size: 2rem; /* Adjust as needed */
    margin-bottom: 20px;
    color: rgb(0, 84, 147);
}

.lessons-content p {
    font-size: 1.2rem; /* Adjust as needed */
    line-height: 1.6; /* Adjust for better readability */
    margin-bottom: 20px;
    text-align: left;
}

.lessons-content a {
  color: #0088cc;
  outline: none;
  text-decoration: none;
}

.lessons-content a:hover {
  text-decoration: underline;
}

.lessons-list {
    font-size: 1.2rem; /* Adjust as needed */
}

.lessons-list a {
  font-size: 1.2rem; /* Adjust as needed */
  color: #0088cc;
  outline: none;
  text-decoration: none;
}

.lessons-list a:hover {
  text-decoration: underline;
}

.lessons-list li {
  font-size: 1.2rem; /* Adjust as needed */
  line-height: 1.6; /* Adjust for better readability */
  margin-bottom: 20px;
  text-align: left;
}

.principle-section {
    margin-bottom: 40px;
  }
  
.principle-section h2 {
  font-size: 1.5rem; /* Adjust title size */
  color: rgb(0, 84, 147);
  margin-bottom: 15px;
  text-align: left;
}

.principle-section p {
  font-size: 1.2rem;
  line-height: 1.6; /* Adjust for better readability */
  margin-bottom: 10px;
}

.big-bullet {
  list-style-type: disc; /* Default disc */
  list-style-position: inside; /* Bullets inside the content */
  margin-left: 20px; /* Adjust for indent */
}

.big-bullet li {
  font-size: 1.2rem; /* Adjust the text size as needed */
  line-height: 1.6;
  margin-bottom: 10px; /* Space between items */
}

.big-bullet li::marker {
  font-size: 1.5rem; /* Makes the bullet point bigger */
  color: rgb(0, 84, 147); /* Optional: Customize bullet color */
}

.highlighted-text {
  background-color: #f0f0f0; /* Light grey background */
  padding: 5px 5px; /* Increased left and right padding */
  border-radius: 5px; /* Slight rounded corners */
  font-family: monospace; /* Optional: Makes it look like code */
  font-size: 1rem; /* Adjust text size */
  display: inline-block; /* Keeps the width constrained to the content */
  color: #333; /* Dark text color for contrast */
}

.lessons-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.lessons-responsive-image {
  max-width: 100%;
  height: auto;
  /* Add padding or margin for mobile if needed */
}

  
  
  
  