.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  background-color: #f8f9fa; /* Adjust based on your theme */
  text-align: center;
  padding: 20px;
}

.logo-footer-image {
  width: 120px;
  max-width: 100%;
}

.legal-contact {
  text-align: right;
}

.legal-contact p {
  margin: 0;
  font-weight: bold;
}

.legal-contact a {
  display: block; /* Stack items vertically */
  color: #c9c232;
  text-decoration: none;
  margin-top: 10px;
  position: relative;
}

.legal-contact a::after {
  content: '';
  width: 0;
  height: 2px;
  background: #c9c232;
  transition: width 0.3s ease;
  position: absolute;
  left: 0;
  bottom: -2px;
  display: inline-block;
}

.legal-contact a:hover::after {
  width: 100%; /* Expand the line to full width of the text on hover */
}

.social-links {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start; /* Align links to the start (left) */
  justify-content: center;
  gap: 15px; /* Adjust gap between icons */
  padding: 10px;
  background-color: #f5f5f5; /* Background color of the box */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 300px; /* Limit the box width */
  margin: 0 auto; /* Center the box horizontally */
}

.social-link img {
  width: 50px; /* Size of icons */
  height: 50px;
  transition: transform 0.3s ease; /* Smooth animation on hover */
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
  }

  .legal-contact {
    text-align: center;
    margin-bottom: 20px;
  }
}

.separator-line {
  width: 50%;
  border: 1px solid #FFBB00;
  margin-bottom: 20px;
}
