.about-me-section {
  display: flex;
    flex-direction: column; /* Align text and image in a row */
    width: 70%;
    background-color: #ffffff; /* Optional: Add background color */
    margin: auto;
    margin-top: 30px;
}

.about-me-content {
    flex: 2;
    padding-right: 20px; /* Space between text and image */
}

.about-me-content h1 {
    font-size: 2.5rem; /* Adjust as needed */
    margin-bottom: 20px;
    color: rgb(0, 84, 147);
}

.about-me-content p {
    font-size: 1rem; /* Adjust as needed */
    margin-bottom: 15px;
    text-align: left;
}

.about-me-content p1 {
    font-size: 1.5rem; /* Adjust as needed */
    margin-bottom: 20px;
    font-style: italic;
    color: black;
    text-align: left;
}

.about-me-content p2 {
  font-size: 1.3rem; /* Adjust as needed */
  margin-bottom: 20px;
  color: black;
  text-align: left;
}

.about-me-image {
    flex: 0 0 150px; /* Adjust width of image container */
    margin-bottom: 20px; /* Add some space below the image */
  }
  
  .about-me-image img {
    height: inherit;
    border-radius: 8px;
    float: left; /* Align the image to the left, allowing text to wrap around */
    margin-right: 20px; /* Add some space to the right of the image */
    border-radius: 50%;
    width: 150px;
    box-shadow: 0 0 7px #FFBB00;
  }

  .social-links-aboutme {
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start; /* Align links to the start (left) */
    justify-content: center;
    gap: 15px; /* Adjust gap between icons */
    padding: 10px;
    max-width: 300px; /* Limit the box width */
    margin: 0 auto; /* Center the box horizontally */
    border-radius: 50%;
    
  }
  
  .social-links-aboutme img {
    width: 30px; /* Size of icons */
    height: 30px;
    transition: transform 0.3s ease; /* Smooth animation on hover */
  }
  
  .social-links-aboutme:hover img {
    transform: scale(1.1); /* Slightly enlarge the icon on hover */
  }
  
  .social-link-button {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 16px;
    background-color: white; /* Google Blue */
    color: #4285f4;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .google-logo {
    width: 20px;
    margin-right: 10px;
  }