/* Container styling */
.manage-users-container {
    display: flex;
    flex-direction: column; /* Align text and image in a row */
    width: 70%;
    background-color: #ffffff; /* Optional: Add background color */
    margin: auto;
    margin-top: 30px;
}

.manage-users-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5em;
  }