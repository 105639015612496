/* HomePage.css */
.hero-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #04295b; /* Dark Blue Background */
    padding: 80px 20px;
}

.hero-title {
    font-size: 3rem;
    font-weight: bold;
    color: #ffffff;
}

.hero-tagline {
    font-size: 1.5rem;
    color: #ffffff;
    margin-top: 10px;
}

.hero-highlight {
    color: #ffffff;
    font-weight: bold;
}

.hero-subtext {
    font-size: 1.2rem;
    margin-top: 10px;
    color: #ffffff;
}

.hero-button {
    display: inline-block;
    margin-top: 20px;
    padding: 12px 20px;
    background-color: #0069d2;
    color: #fff;
    text-decoration: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: bold;
    transition: background 0.3s ease;
}

.hero-button:hover {
    background-color: #0056b3;
}

/* Introduction.css - Updated for Linear Layout */
.intro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 60px 20px;
    background: #f9f9f9;
}

.intro-content {
    max-width: 800px;
}

.intro-image img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.intro-text h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
}

.intro-text p {
    font-size: 1.1rem;
    color: #555;
}

/* Sections - Unified Style */
.section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 60px 20px;
    background: #fff;
}

.section-container img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
}

.section-container h2 {
    font-size: 1.8rem;
    color: #222;
    margin-bottom: 10px;
}

.section-container p {
    font-size: 1.1rem;
    color: #555;
    max-width: 800px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .intro-content {
        flex-direction: column;
        text-align: center;
    }

    .intro-image img {
        margin-bottom: 10px;
    }
}
